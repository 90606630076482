<template>
  <el-main>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="首推商家：" prop="top_sellers">
        <div class="itemBox">
          <el-input disabled v-model="ruleForm.top_sellers[0].store_name"></el-input>
          <el-button type="primary" @click="chooseShowDialog('top_sellers', 0)">选择</el-button>
          <div class="tips">仅支持一个商家</div>
        </div>
      </el-form-item>
      <el-form-item label="主推商家：" prop="main_sellers">
        <div>
          <vuedraggable>
            <transition-group>
              <div class="itemBox mb20" v-for="(item, index) in ruleForm.main_sellers" :key="index">
                <el-input disabled v-model="item.store_name"></el-input>
                <el-button type="primary" @click="chooseShowDialog('main_sellers', index)">选择</el-button>
                <img
                  @click="delSellers('main_sellers', index)"
                  class="closeImg"
                  v-if="ruleForm.main_sellers.length > 1"
                  src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/close.png"
                  alt=""
                />
              </div>
            </transition-group>
          </vuedraggable>
          <div>
            <el-button type="text" @click="clickAddBtn('main_sellers')">+ 添加主推商家</el-button>
            <span class="tips">建议不超过十个</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="推荐商家：" prop="recommend_sellers">
        <div>
          <vuedraggable>
            <transition-group>
              <div class="itemBox mb20" v-for="(item, index) in ruleForm.recommend_sellers" :key="index">
                <el-input disabled v-model="item.store_name"></el-input>
                <el-button type="primary" @click="chooseShowDialog('recommend_sellers', index)">选择</el-button>
                <img
                  @click="delSellers('recommend_sellers', index)"
                  class="closeImg"
                  v-if="ruleForm.recommend_sellers.length > 1"
                  src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/close.png"
                  alt=""
                />
              </div>
            </transition-group>
          </vuedraggable>
          <div>
            <el-button type="text" @click="clickAddBtn('recommend_sellers')">+ 添加推荐商家</el-button>
            <span class="tips">建议不超过8个</span>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <!-- 选择商家弹框 -->
    <el-dialog title="选择商家" :visible.sync="pageFlag" width="52%">
      <div class="chooseShopBox">
        <div class="searchBox">
          <el-input v-model="searchShopName" placeholder="请输入商家名称"></el-input>
          <el-button type="primary" @click="searchOne">搜索</el-button>
        </div>
        <div class="showAllList" @scroll="loadPageMore">
          <div class="showAllItem" v-for="(item, index) in allShopList" :key="index">
            <span>{{ item.store_name }}</span>
            <el-button type="text" @click="chooseStore(item)">选择</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 保存按钮 -->
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import vuedraggable from 'vuedraggable';
import Preservation from '@/components/preservation';
import _ from 'lodash';
export default {
  components: {
    vuedraggable,
    Preservation,
  },
  data() {
    function topCheck(rule, value, callback) {
      let flag = true;
      for (let i = 0; i < value.length; i++) {
        if (!value[i].id) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        callback(new Error('请选择首推商家'));
      } else {
        callback();
      }
    }
    function mainCheck(rule, value, callback) {
      let flag = true;
      for (let i = 0; i < value.length; i++) {
        if (!value[i].id) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        callback(new Error('请选择主推商家'));
      } else {
        callback();
      }
    }
    function recommendCheck(rule, value, callback) {
      let flag = true;
      for (let i = 0; i < value.length; i++) {
        if (!value[i].id) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        callback(new Error('请选择推荐商家'));
      } else {
        callback();
      }
    }
    return {
      ruleForm: {
        top_sellers: [{}],
        main_sellers: [{}],
        recommend_sellers: [{}],
      },
      rules: {
        top_sellers: [{ required: true, validator: topCheck, trigger: 'change' }],
        main_sellers: [{ required: true, validator: mainCheck, trigger: 'change' }],
        recommend_sellers: [{ required: true, validator: recommendCheck, trigger: 'change' }],
      },
      pageFlag: false,
      type: 'top_sellers',
      chooseIndex: -1,
      allShopList: [],
      searchShopName: '',
      page: 1,
      rows: 10,
      total: 0,
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    clearDialogStatus() {
      this.page = 1;
      this.searchShopName = '';
      this.allShopList = [];
      this.getAllShopList();
      this.pageFlag = true;
    },
    chooseShowDialog(type, index) {
      this.type = type;
      this.chooseIndex = index;
      this.clearDialogStatus();
    },
    loadPageMore(e) {
      if (e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight) {
        // 业务逻辑...
        if (this.total / this.rows > this.page) {
          this.page++;
          this.getAllShopList();
        }
      }
    },
    // 获取全部商家列表
    getAllShopList() {
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.searchShopName) obj.store_name = this.searchShopName;
      this.$axios.post(this.$api.store.storeList, obj).then(res => {
        if (res.code === 0) {
          this.allShopList = this.allShopList.concat(res.result.list);
          this.total = res.result.total_number;
        }
      });
    },
    searchOne() {
      this.page = 1;
      this.allShopList = [];
      this.getAllShopList();
    },
    chooseStore(data) {
      this.ruleForm[this.type][this.chooseIndex] = data;
      this.pageFlag = false;
    },
    clickAddBtn(type) {
      this.ruleForm[type].push({});
    },
    delSellers(type, index) {
      this.ruleForm[type].splice(index, 1);
    },
    Preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm);
          this.$axios.post(this.$api.Tikmall.setBaseInfo, obj).then(res => {
            if (res.code === 0) {
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getInfo() {
      this.$axios.post(this.$api.Tikmall.getInfo).then(res => {
        if (res.code === 0) {
          if (res.result.info) {
            for (const key in this.ruleForm) {
              if (res.result.info[key]) this.ruleForm[key] = JSON.parse(res.result.info[key]);
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 80px;
  .mb20 {
    margin-bottom: 20px;
  }
  .tips {
    color: #9f9f9f;
    font-size: 12px;
    margin-left: 20px;
  }
  .el-form {
    .itemBox {
      display: flex;
      align-items: center;
      .el-input {
        width: 400px;
      }

      .closeImg {
        width: 20px;
        height: 20px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
  .chooseShopBox {
    .searchBox {
      display: flex;
      align-items: center;
    }
    .showAllList {
      height: 390px;
      width: 100%;
      overflow-y: auto;
      margin-top: 20px;
      .showAllItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
      }
      .showAllItem:hover {
        background-color: #faf5f5ef;
      }
    }
  }
}
</style>
